@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --rounded-btn: 0.35rem;
}

html,
body,
#__next {
  width: 100%;
  /* To smooth any scrolling behavior */
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  /* Allows content to fill the viewport and go beyond the bottom */
  min-height: 100%;
}

html {
  scroll-behavior: smooth;
  /* Prevent text size change on orientation change https://gist.github.com/tfausak/2222823#file-ios-8-web-app-html-L138 */
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

body {
  display: flex;
  /* Allows you to scroll below the viewport; default value is visible */
  overflow-y: auto;
  overscroll-behavior-y: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: scrollbar;
  flex: 1;
  flex-shrink: 0;
  flex-basis: auto;
  flex-direction: column;
}

body.stop-transitions * {
  transition: none !important;
}

#nprogress .bar {
  background: #142852 !important;
}

#nprogress .peg {
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0, rgba(255, 255, 255, 0.8) -6px -2px 16px 0 !important;
}
